import { pick } from "@styled-system/props";
import { Text, Flex, SystemProps } from "flicket-ui";
import Image from "~components/common/Image";
import { Card } from "../Card";
import { ExtendedFile } from "~graphql/sdk";
import Link from "next/link";

interface PrizeCardProps extends SystemProps {
  title: string;
  description: string;
  quantity?: number;
  image: ExtendedFile;
  link: string;
}

export default function PrizeCard(props: PrizeCardProps) {
  const { title, description, quantity, image, link } = props;

  return (
    <Card {...pick(props)}>
      <Link href={link}>
        <Flex flexDirection={["column", "column", "row"]} cursor="pointer">
          {/* Image */}
          {image ? (
            <Image
              flex={[1, 1, "0 0 335px"]}
              image={image}
              alt={typeof title === "string" ? title : ""}
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          ) : null}

          {/* Content */}
          <Flex
            style={{ gap: "16px" }}
            flex={1}
            background={"white"}
            flexDirection={"column"}
            padding={4}
          >
            {title ? (
              <Text color="N800" fontSize={4} fontWeight={"extraBold"}>
                {title}
              </Text>
            ) : null}

            <Text fontSize={3}>{description}</Text>

            {quantity > 0 ? (
              <Text color="N700" fontWeight={"extraBold"} fontSize={3}>
                {`${quantity} to give away`}
              </Text>
            ) : null}
          </Flex>
        </Flex>
      </Link>
    </Card>
  );
}
